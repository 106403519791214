<template>
  <div>
    <CategoryLabFilter
      v-if="$can('filter', 'module_system_settings.settings.laboratory.categories')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable
        titleTable="Laboratories category"
        iconTable="GitBranchIcon"
      />
      <div class="m-1">
        <CategoryLabEdit
          v-if="isEditCategoryLabSidebarActive"
          :is-edit-category-lab-sidebar-active.sync="
            isEditCategoryLabSidebarActive
          "
          :itemEdit="itemEdit"
          @editCategoryLabSuccess="editCategoryLabSuccess"
        />
        <CategoryLabAdd
          :is-add-new-category-lab-sidebar-active.sync="
            isAddNewCategoryLabSidebarActive
          "
          @createCategoryLab="createCategoryLab"
        />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                variant="primary"
                v-if="$can('store', 'module_system_settings.settings.laboratory.categories')"
                @click="isAddNewCategoryLabSidebarActive = true"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Category Lab</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(view)="data">
          <b-form-checkbox
            v-model="data.detailsShowing"
            @change="data.toggleDetails"
          />
        </template>
        <template #row-details="data">
          <ul class="list-unstyled my-2 facility">
            <li v-for="(item, i) in data.item.laboratories" :key="i">
              <b-badge
                variant="light-primary"
                :to="{
                  name: 'admin-laboratory',
                  params: { id: item.id },
                }"
                ><span>{{ item.name }}</span></b-badge
              >
            </li>
          </ul>
          <b-button
            size="sm"
            class="my-1"
            variant="outline-primary"
            @click="data.toggleDetails"
          >
            hide details
          </b-button>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(laboratories)="data">
          {{
            data.item.laboratories.length > 0
              ? data.item.laboratories[0].name
              : "-"
          }}, ...
        </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'Edit'"
            v-if="$can('update', 'module_system_settings.settings.laboratory.categories')"
            @click="editCategoryLab(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            v-b-tooltip.hover.top="'Delete'"
            v-if="$can('destroy', 'module_system_settings.settings.laboratory.categories')"
            @click="deleteCategoryLab(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import axiosCV from "@/services/admin/consults/laboratoryCategories";
import Ripple from "vue-ripple-directive";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import vSelect from "vue-select";

import CategoryLabEdit from "./CategoryLabEdit.vue";
import CategoryLabAdd from "./CategoryLabAdd.vue";
import CategoryLabFilter from "./CategoryLabFilter.vue";
import { ref } from "vue-demi";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    BFormCheckbox,
    CategoryLabEdit,
    CategoryLabAdd,
    CategoryLabFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    vSelect,
    TitleTable,
    GoBack,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        { key: "view", label: "View", sortable: false },
        "id",
        // A column that needs custom formatting
        { key: "name", label: "Name", sortable: true },
        { key: "laboratories", label: "laboratories", sortable: true },
        { key: "price", label: "Price", sortable: true },
        // A regular column
        // A regular column
        { key: "status", label: "Status" },
        // A virtual column made up from two fields
        { key: "actions", label: "Actions" },
      ],
      items: null,
      loading: false,
      isEditCategoryLabSidebarActive: false,
      isAddNewCategoryLabSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      laboratoryFilter: null,
      filtro: false,
    };
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getCategoryLab();
      } else {
        this.searchFilter(this.laboratoryFilter);
      }
    },
  },
  mounted() {
    this.getCategoryLab();
  },
  methods: {
    getCategoryLab() {
      axiosCV
        .categoryLabList(this.perPage)
        .then(({ data, total, current_page, per_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.perPage = per_page;
          this.currentPage.page = current_page;
        });
    },
    editCategoryLab(item) {
      this.isEditCategoryLabSidebarActive = true;
      const itemSelected = {
        ...item,
        laboratories: item.laboratories.map((item) => item.id)
      }
      this.itemEdit = ref(JSON.parse(JSON.stringify(itemSelected)));
    },
    editCategoryLabSuccess(value) {
      if (value) {
        this.isEditCategoryLabSidebarActive = false;
        this.$refs.toast.success("CategoryLab updated successfully");
        this.pageChanged(this.currentPage.page);
      } else {
        this.$refs.toast.error("Error updating CategoryLab");
      }
    },
    createCategoryLab(value) {
      if (value) {
        this.$refs.toast.success("CategoryLab added successfully");
        this.isAddNewCategoryLabSidebarActive = false;
        this.pageChanged(this.currentPage.page);
      } else {
        this.$refs.toast.danger("CategoryLab not added");
      }
    },
    deleteCategoryLab(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosCV.categoryLabDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "CategoryLab has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosCV
          .categoryLabPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosCV
          .categoryLabFilterPagination(
            this.perPage,
            page,
            this.diagnosticFilter
          )
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.laboratoryFilter = value;
        axiosCV
          .categoryLabFilter(this.perPage, value)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getCategoryLab();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style>
</style>